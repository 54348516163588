import React, { useEffect, useState } from 'react';
import { bool, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl } from '../../util/reactIntl';
import { camelize } from '../../util/string';
import { propTypes } from '../../util/types';
import { useConfiguration } from '../../context/configurationContext';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { useHistory, useLocation } from 'react-router-dom';
import { useIntl } from '../../util/reactIntl';
import PageBuilder from '../../containers/PageBuilder/PageBuilder';

import FallbackPage from './FallbackPage';
import { ASSET_NAME } from './LandingPage.duck';

export const LandingPageComponent = props => {
  const { pageAssetsData, inProgress, error, history, intl, location, routeConfiguration } = props;

  const [currentScrollFromTop, setCurrentScrollFromTop] = useState(0)


  const someScrollFromTop = () => {
    // 83 - current topbar height
    if (typeof window !== 'undefined') {
      const scrollValue = !!window && window?.pageYOffset
      setCurrentScrollFromTop(scrollValue)
    }
  }

  useEffect(() => {
    document.addEventListener('scroll', () => someScrollFromTop());
  }, [])

  useEffect(() => {
    return () => { document.addEventListener('scroll', () => someScrollFromTop()) };
  }, []);
  
  return (
    <PageBuilder
      pageAssetsData={pageAssetsData?.[camelize(ASSET_NAME)]?.data}
      inProgress={inProgress}
      error={error}
      fallbackPage={<FallbackPage error={error} />}
      history={history}
      intl={intl}
      location={location}
      routeConfiguration={routeConfiguration}
      withSectionSeparator
      currentScrollFromTop={currentScrollFromTop}
    />
  );
};

LandingPageComponent.propTypes = {
  pageAssetsData: object,
  inProgress: bool,
  error: propTypes.error,
};

const mapStateToProps = state => {
  const { pageAssetsData, inProgress, error } = state.hostedAssets || {};
  return { pageAssetsData, inProgress, error };
};


const EnhancedLandingPage = props => {
  const config = useConfiguration();
  const routeConfiguration = useRouteConfiguration();
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();

  return (
    <LandingPageComponent
      config={config}
      routeConfiguration={routeConfiguration}
      intl={intl}
      history={history}
      location={location}
      {...props}
    />
  );
};


// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(
  connect(mapStateToProps),
)(EnhancedLandingPage);

export default LandingPage;
